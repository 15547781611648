






























































































































































































import { debounceProcess } from "@/helpers/debounce";
import { ResponsePagination } from "@/models/constant/interface/common.interface";
import { Messages } from "@/models/enums/messages.enum";
import { ContactData } from "@/models/interface/contact.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { DataWarehouseBranch } from "@/models/interface/logistic.interface";
import { contactServices } from "@/services/contact.service";
import { logisticServices } from "@/services/logistic.service";
import moment from "moment";
import { Component, Vue } from "vue-property-decorator";
import { salesOrderServices } from "@/services/salesorder.service";
import {
  DataListInternalContract,
  DataResponseReportUnitRented,
} from "@/models/interface/salesOrder.interface";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { assetsServices } from "@/services/assets.service";

interface LoadingComponent {
  loadingFind: boolean;
  loadingBastNumber: boolean;
  download: boolean;
  loadingCustomerName: boolean;
  loadingBranch: boolean;
  loadingCategory: boolean;
  loadingDownload: boolean;
  loadingInternalContract: boolean;
}

@Component
export default class ReportsUnitRented extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = this.$form.createForm(this, { name: "reportsUnitRented" });
  page = 1;
  limit = 10;
  params: RequestQueryParamsModel = {};
  totalData = 0;
  loading: LoadingComponent = {
    loadingFind: false,
    loadingBastNumber: false,
    download: false,
    loadingCustomerName: false,
    loadingBranch: false,
    loadingCategory: false,
    loadingDownload: false,
    loadingInternalContract: false,
  };
  columnsTable = [
    {
      title: this.$t("lbl_no"),
      dataIndex: "no",
      key: "no",
      width: 100,
      scopedSlots: { customRender: "no" },
    },
    {
      title: this.$t("lbl_ic_no"),
      dataIndex: "icNumber",
      key: "icNumber",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_bast_no"),
      dataIndex: "bastNumber",
      key: "bastNumber",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_bast_date"),
      dataIndex: "bastDate",
      key: "bastDate",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_customer_name"),
      dataIndex: "customerName",
      key: "customerName",
      width: 170,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_location"),
      dataIndex: "customerLocation",
      key: "customerLocation",
      width: 240,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_unit_code"),
      dataIndex: "unitCode",
      key: "unitCode",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_equipment"),
      dataIndex: "equipment",
      key: "equipment",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_merk"),
      dataIndex: "merk",
      key: "merk",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_sn"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
    {
      title: this.$t("lbl_specification"),
      dataIndex: "specification",
      key: "specification",
      width: 150,
      scopedSlots: { customRender: "isNull" },
    },
  ];
  formRules = {
    branch: {
      label: "Branch",
      name: "branch",
      placeholder: "Insert Branch",
      decorator: ["branch"],
    },
    category: {
      label: "lbl_category",
      name: "category",
      placeholder: "lbl_category_placeholder",
      decorator: ["category"],
    },
    asOfDate: {
      label: "lbl_as_of_date",
      name: "asOfDate",
      placeholder: "lbl_as_of_date_placeholder",
      decorator: [
        "asOfDate",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    customerName: {
      label: "lbl_customer_name",
      name: "customerName",
      placeholder: "lbl_customer_name_placeholder",
      decorator: ["customerName"],
    },
    internalContractNo: {
      label: "Internal Contract Number",
      name: "internalContractNo",
      placeholder: "Insert Internal Contract Number",
      decorator: ["internalContractNo"],
    },
  };
  dataSource: DataResponseReportUnitRented[] = [];
  dataCustomerName: ContactData[] = [];
  dataInternalContract: DataListInternalContract[] = [];
  dataBranch: DataWarehouseBranch[] = [];
  dataCategory: string[] = [];
  idCustomer = "";
  idBranch = "";

  handleChangeBranch(value) {
    this.idBranch = value;
    this.form.resetFields(["internalContractNo"]);
    this.getInternalContract("");
  }
  handleChangeCustomer(value) {
    this.idCustomer = value;
    this.form.resetFields(["internalContractNo"]);
    this.getInternalContract("");
  }
  getBranch(valueSearch) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (valueSearch)
      params.search = `name~*${valueSearch}*_OR_code~*${valueSearch}*_OR_address~*${valueSearch}`;
    this.loading.loadingBranch = true;
    logisticServices
      .listWarehouseBranch(params, "")
      .then((response) => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.loadingBranch = false));
  }
  getCategory(valueSearch) {
    const params: RequestQueryParamsModel = {};
    if (valueSearch) params.search = `${valueSearch}`;
    this.loading.loadingCategory = true;
    assetsServices
      .listAssetCategoryDistinct(params)
      .then((response) => {
        this.dataCategory = response;
      })
      .finally(() => (this.loading.loadingCategory = false));
  }
  responseViewTable(response) {
    this.$router.push(`/sales/pindah-lokasi/cetak/${response.data.id}`);
  }
  getInternalContract(valueSearch) {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
    };
    if (valueSearch)
      params.search = `documentNo~*${valueSearch}*_AND_status!ENDED`;
    else params.search = "status!ENDED";
    if (this.idBranch) {
      if (params.search)
        params.search += `_AND_branch.secureId~${this.idBranch}`;
      else params.search = `branch.secureId~${this.idBranch}`;
    }
    if (this.idCustomer) {
      if (params.search)
        params.search += `_AND_customer.secureId~${this.idCustomer}`;
      else params.search = `customer.secureId~${this.idCustomer}`;
    }
    this.loading.loadingInternalContract = true;
    salesOrderServices
      .getListInternalContract(params)
      .then((response) => (this.dataInternalContract = response.data))
      .finally(() => (this.loading.loadingInternalContract = false));
  }
  getCustomerName(valueSearch) {
    const params: RequestQueryParamsModel = {
      limit: 10,
      page: 0,
      search: "customer~true_AND_active~true",
    };
    if (valueSearch)
      params.search += `_AND_firstName~*${valueSearch}*_OR_lastName~*${valueSearch}*`;
    this.loading.loadingCustomerName = true;
    contactServices
      .listContactData(params)
      .then((response) => (this.dataCustomerName = response.data))
      .finally(() => (this.loading.loadingCustomerName = false));
  }
  responsePageSizeChange(response: ResponsePagination): void {
    this.limit = response.size;
    this.page = 1;
    this.onSubmit(false);
  }
  responseCurrentPageChange(response: ResponsePagination): void {
    this.page = response.page;
    this.onSubmit(false);
  }
  checkValue(value): string {
    if (value && !value.includes("undefined")) {
      return value;
    } else {
      return ",ALL";
    }
  }
  checkParams(res): string {
    let params = "";
    // company is mandatory
    params += this.$store.state.authStore.authData.companyName;
    params += this.checkValue(
      `,${
        this.dataBranch.find((dataFind) => dataFind.id === res["branch"])?.name
      }`
    );
    params += this.checkValue(`,${res["category"]}`);
    params += this.checkValue(`,${res["asOfDate"]}`);
    params += this.checkValue(
      `,${this.dataCustomerName
        .find((dataFind) => dataFind.id === res["customerName"])
        ?.fullName?.replaceAll(",", " ")}`
    );
    params += this.checkValue(`,${res["internalContractNo"]}`);

    return params;
  }
  assignSearch(key, value, and): string {
    if (key === "branch" && value)
      return and + `internalContract.branch.secureId~${value}`;
    else if (key === "category" && value)
      return and + `asset.assetCategory.categoryId~*${value.toLowerCase()}.*`;
    else if (key === "customerName" && value)
      return and + `internalContract.customer.secureId~${value}`;
    else if (key === "internalContractNo" && value)
      return and + `internalContract.documentNo~*${value}*`;
    else if (key === "asOfDate" && value)
      return (
        and +
        `createdDate<=${moment(value, DEFAULT_DATE_FORMAT)
          .set({ hour: 23, minute: 59, second: 59 })
          .utcOffset("+07")
          .format()}`
      );
    else return "";
  }
  dynamicSearch(res): string {
    let search = "";
    Object.keys(res).forEach((key) => {
      if (!search) {
        search = this.assignSearch(key, res[key], "");
      } else {
        search += this.assignSearch(key, res[key], "_AND_");
      }
    });
    return search;
  }
  handleReset() {
    this.form.resetFields();
    this.form.setFieldsValue({
      asOfDate: moment().format(DEFAULT_DATE_FORMAT),
    });
  }
  onSubmit(firstSearch): void {
    this.form.validateFields((err, values) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        page: this.page - 1,
        limit: this.limit,
        sorts: "internalContract.customer.firstName:asc",
      };
      params.search = this.dynamicSearch(values);
      if (firstSearch) {
        params.page = 0;
        this.page = 1;
      }
      this.loading.loadingFind = true;
      salesOrderServices
        .getListReportUnitRented(params)
        .then((response) => {
          this.totalData = response.totalElements;
          this.dataSource = response.data.map((dataMap, index) => {
            return {
              ...dataMap,
              nno:
                response.currentPage === 0
                  ? index + 1
                  : this.limit * (this.page - 1) + index + 1,
            };
          });
        })
        .finally(() => (this.loading.loadingFind = false));
    });
  }
  handleDownload() {
    this.form.validateFields((err, res) => {
      if (err) return;
      const params: RequestQueryParamsModel = {
        page: this.page - 1,
        limit: this.limit,
        sorts: "internalContract.customer.firstName:asc",
      };
      params.search = this.dynamicSearch(res);
      params.params = this.checkParams(res);
      this.loading.loadingDownload = true;
      salesOrderServices
        .downloadReportUnitRented(params)
        .then((response) => {
          if (response) {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "report_unit_rented.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        })
        .finally(() => (this.loading.loadingDownload = false));
    });
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }

  created() {
    this.getCustomerName = debounceProcess(this.getCustomerName, 200);
    this.getBranch = debounceProcess(this.getBranch, 400);
    this.getCategory = debounceProcess(this.getCategory, 400);
    this.getInternalContract = debounceProcess(this.getInternalContract, 400);

    this.getCustomerName("");
    this.getBranch("");
    this.getCategory("");
    this.getInternalContract("");
  }

  mounted() {
    this.form.setFieldsValue({
      asOfDate: moment().format(DEFAULT_DATE_FORMAT),
    });
  }

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }
}
